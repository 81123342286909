import React from 'react'
import { Link } from 'gatsby'

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='shell'>
        <a
          href='#'
          className='logo-dark'
          style={{ backgroundImage: 'url(/assets/images/logo-boldgreed-dark.svg)' }}
        ></a>

        <div className='footer__cols'>
          <div className='footer__col'>
            <p>
              For inquiries and bookings:
              <br />
              <a href='mailto:info@boldbreed.de'>info@boldbreed.de</a>
              <br />
              <a href='tel:+493030877555'>+49 (0)30 308 77 555</a>
            </p>
          </div>

          <div className='footer__col'>
            <ul>
              <li>
                <Link to='/contact/'>Contact</Link>
              </li>

              <li>
                <a href='/imprint/'>Imprint</a>
              </li>

              <li>
                <a href='/privacy/'>Privacy</a>
              </li>
            </ul>
          </div>
        </div>

        <div className='socials'>
          <ul>
            <li>
              <a
                href='https://www.youtube.com/channel/UColdLKVC4c9JdNF6wED6-vw'
                target='_blank'
              >
                <i className='fa fa-youtube-play' aria-hidden='true'></i>
              </a>
              <a
                href='https://www.instagram.com/boldbreed_agency_for_creatives/'
                target='_blank'
                className='instagram-icon'
              >
                <i className='fa fa-instagram' aria-hidden='true'></i>
              </a>
              <a
                href='https://www.linkedin.com/company/boldbreed-agency-for-creatives/?viewAsMember=true'
                target='_blank'
                className='linkedin-icon'
              >
                <i className='fa fa-linkedin' aria-hidden='true'></i>
              </a>
            </li>

            <li>
              <div className='nl-container'>
                <button className='nl-btn rm-open-popup'>
                  Subscribe to newsletter
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div className='silber-wrap'>
          <div className='silber'>
            <p className='text'>Boldly Designed by</p>
            <a href='https://silberpuls.de/' target='_blank'>
              <img src='/assets/images/logo-silberpuls.svg' />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import moment from 'moment'

export const convertSpacesToUnderscore = string => string.replace(/\s+/g, '_')

export const pastExpirationDate = date => {
  if (!date) return false

  const expirationDate = moment(date, 'DD/MM/YYYY hh:mm A')
  const today = moment(date, 'DD/MM/YYYY hh:mm A')

  return today.isAfter(expirationDate)
}
